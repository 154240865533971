








































































































































































































































































































































































































import { Vue, Component, Watch, Prop } from 'vue-property-decorator'
import { mapGetters, mapState } from 'vuex'
import { PosteContext } from '@/types/Poste'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { Ability } from '@/types/Ability'
import ErrorDisplay from '@/components/ErrorDisplay.vue'
import PhoneInput from '@/components/Tools/PhoneInput.vue'
import { format_phone_number } from '@/utils/helpers'

@Component({
    computed: {
        ...mapGetters('auth', ['authUser', 'can', 'cannot', 'isA', 'isNotA']),
        ...mapState('centre', ['centreSelect'])
    },
    components: {
        PhoneInput,
        ErrorDisplay,
        'font-awesome-icon': FontAwesomeIcon
    }
})
export default class ContactCentre extends Vue {
    @Prop() context?: string
    @Prop() centre_user_id?: number
    @Prop() add_user?: boolean
    @Prop() mode_responsable?: boolean
    @Prop() has_reponsable?: boolean
    @Prop() has_chef_centre?: boolean


    Ability = Ability
    formatPhoneNumber = format_phone_number
    PosteContext = PosteContext

    modeResponsable = false
    showModalMessageDissociate = false
    showModalAffecterContact = false
    messageErreurSimple =''
    showError = false
    contact = ''
    civility = ''
    name_intervenant = ''
    first_name = ''
    email = ''
    telephone = ''
    telephone_fixe = ''
    phoneNumber = ''
    phoneFixeNumber = ''
    responsabilite_centre = ''
    responsabilite_concours = ''
    postes_ids = []
    name_search = ''
    createContact = false
    addContact = false
    msgSuccess_toast = ''
    selectedIntervenant = ''
    confirmation = 0
    usersListFromSearch = {}
    showErrorConflict = false
    showResultats = false
    lockInput = false
    loading_resp = false
    isBis = false
    add_contact = false
    is_responsable = 0
    is_cdc = 0
    liste_postes: Array<any> = []
    userEdit: any = null
    centreUserEdit: any = null
    editUserDisabled = false

    @Watch('loading', { deep: true, immediate: true })
    initGlobal () {
        this.initContact()
    }

    @Watch('userEdit', { deep: true, immediate: true })
    checkUserEdit () {
        this.editUserDisabled = false
        if (this.userEdit) {
            if (this.userEdit.name.trim().length === 0) {
                this.editUserDisabled = true
            }
            if (this.userEdit.first_name.trim().length === 0) {
                this.editUserDisabled = true
            }
            if (this.userEdit.email.trim().length === 0) {
                this.editUserDisabled = true
            }


        } else {
            this.editUserDisabled = true
        }
    }

    error () {
        const retour = this.$store.getters[(this.modeResponsable ? 'affectationResponsable' : 'centre') + '/error']
        return retour || null
    }

    loading () {
        return this.$store.getters[(this.modeResponsable ? 'affectationResponsable' : 'centre') + '/loading']
    }

    loading_search () {
        return this.$store.getters[(this.modeResponsable ? 'affectationResponsable' : 'centre') + '/loading_search']
    }

    countError () {
        const retour = this.$store.getters[(this.modeResponsable ? 'affectationResponsable' : 'centre') + '/countError']
        return retour || 0
    }

    error_conflict () {
        const retour = this.$store.getters[(this.modeResponsable ? 'affectationResponsable' : 'centre') + '/errorConflict']
        return retour || null
    }

    updatePhoneNumberInputDataEdit () {
        this.editUserDisabled = false
        let retour = ''
        if ((this.$refs.phoneInputEdit as any)) {
            if ((this.$refs.phoneInputEdit as any).infosPhoneInput) {
                if ((this.$refs.phoneInputEdit as any).infosPhoneInput.isValidatedPhoneNumber) {
                    retour = (this.$refs.phoneInputEdit as any).infosPhoneInput.formattedPhoneNumber
                } else {
                    this.userEdit.telephone = ''
                }
            }
        }
        return retour
    }

    updatePhoneFixeNumberInputDataEdit () {
        this.editUserDisabled = false
        let retour = ''
        if ((this.$refs.phoneFixeInputEdit as any)) {
            if ((this.$refs.phoneFixeInputEdit as any).infosPhoneInput) {
                if ((this.$refs.phoneFixeInputEdit as any).infosPhoneInput.isValidatedPhoneNumber) {
                    retour = (this.$refs.phoneFixeInputEdit as any).infosPhoneInput.formattedPhoneNumber
                } else {
                    this.userEdit.telephone_fixe = ''
                }
            }
        }
        return retour
    }

    // Edite un contact sélectionné
    editCurrentUser (centreuser: any) {
        if (!this.$store.state.poste.loading) {
            this.$store.dispatch('poste/getPostes', { context: (PosteContext.CONTEXT_CHEF_CENTRE + PosteContext.CONTEXT_RESPONSABLE_CENTRE + PosteContext.CONTEXT_SECRETAIRE) }).then(() => {
                this.liste_postes = this.$store.state.poste.postes
                this.userEdit = centreuser.user
                this.centreUserEdit = centreuser
                const postes_aff = centreuser.postes.filter((p: any) => p.context !== PosteContext.CONTEXT_AFFECTATION && p.context !== PosteContext.CONTEXT_SELECTION && p.context !== PosteContext.CONTEXT_SELECTION)
                this.userEdit.postes_ids = []
                for (const p in postes_aff) {
                    this.userEdit.postes_ids.push(postes_aff[p].id)
                }
                this.is_responsable = this.$store.state.centre.centreSelect.responsable_id === this.userEdit.id ? 1 : 0
                this.is_cdc = this.$store.state.centre.centreSelect.gestionnaire_id === this.userEdit.id ? 1 : 0
                this.userEdit.centre_user_id = centreuser.id
                this.userEdit.responsabilite_centre = centreuser.responsabilite_centre
                this.userEdit.responsabilite_concours = centreuser.responsabilite_concours
            })
        }
    }

    // Annule l'édition d'un contact sélectionné
    cancelEditCurrentUser () {
        this.userEdit = null
        this.centreUserEdit = null
        this.$emit('finish_edit')
    }

    // Annule la création d'un nouveau contact
    cancelCreateContact () {
        this.add_contact = false
        this.$store.state.centre.error_conflict = null
        this.$store.state.affectationResponsable.error_conflict = null
        this.$emit('finish_edit')
    }

    // Maj d'un contact sélectionné
    saveEditCurrentUser () {
        const telephoneTemp = this.updatePhoneNumberInputDataEdit()
        const telephoneFixeTemp = this.updatePhoneFixeNumberInputDataEdit()
        const idInfo = 't_info_' + Math.random()
        const infosToaster = {
            id: idInfo,
            toaster: 'b-toaster-top-right',
            variant: 'primary',
            noCloseButton: true,
            fade: true,
            noAutoHide: true
        }
        this.$bvToast.toast('Enregistrement en cours ...', infosToaster)
        this.msgSuccess_toast = 'Enregistrement terminé.'

        const postes: Array<any> = []
        for (const p in this.userEdit.postes_ids) {
            postes.push({
                poste_id: this.userEdit.postes_ids[p]
            })
        }

        const data: any = {
            payload : {
                id: this.userEdit.id,
                civility: this.userEdit.civility,
                email: this.userEdit.email,
                name: this.userEdit.name,
                first_name: this.userEdit.first_name,
                telephone: telephoneTemp,
                telephone_fixe: telephoneFixeTemp,
                responsabilite_centre: this.userEdit.responsabilite_centre,
                responsabilite_concours: this.userEdit.responsabilite_concours,
                postes: postes,
                temps: 100
            },
            centre_id: this.$store.state.centre.centreSelect.id,
            centre_user_id: this.userEdit.centre_user_id
        }

        this.$store.dispatch('centre/updateUserFromCentre', data)
            .then(() => {
                // Fermeture de la popup aprés validation
                const idSucces = 't_succes_' + Math.random()
                const succesToaster = {
                    id: idSucces,
                    toaster: 'b-toaster-top-right',
                    variant: 'success',
                    noCloseButton: true,
                    fade: true,
                    autoHideDelay: 5000
                }
                this.$bvToast.toast(this.msgSuccess_toast, succesToaster)

                this.userEdit = null
                this.centreUserEdit = null
                this.$emit('finish_edit')
            })
            .catch(() => {
                if (this.$store.state.centre.error_conflict && this.$store.state.centre.error_conflict.message) {
                    this.showErrorConflict = true
                    this.showError = false
                    this.lockInput = true
                } else {
                    this.showErrorConflict = false
                    this.showError = true
                    this.messageErreurSimple = 'Une erreur est survenue :'
                }
            })
            .finally(() => {
                this.$bvToast.hide(idInfo)
            })
    }

    // Affiche le formulaire de création d'un nouveau contact
    showNewContact () {
        this.$store.commit('centre/SET_ERROR', null)
        this.createContact = true
        this.addContact = false
    }

    // Affiche le formulaire de recherche d'un contact
    showSearchContact () {
        this.addContact = true
        this.createContact = false
    }

    // Affiche le message de confirmation pour affecter un contact
    affecterUser (isBis: boolean) {
        this.isBis = isBis
        this.showModalAffecterContact = true
    }

    // Annule l'affichage de confirmation d'affection du contact
    cancelAffecterContact () {
        this.showError = false
        this.messageErreurSimple = ''
        this.showModalAffecterContact = false
    }

    // Init du contact
    initContact () {
        if (this.$props.centre_user_id !== 0 && this.$props.context === 'other') {
            const current_user = this.$store.state.centre.centreSelect.centreusers.find((cu: any) => cu.id === this.$props.centre_user_id)
            this.editCurrentUser(current_user)
        }

        if (this.add_user) {
            this.$store.dispatch('poste/getPostes', { context: (PosteContext.CONTEXT_CHEF_CENTRE + PosteContext.CONTEXT_RESPONSABLE_CENTRE + PosteContext.CONTEXT_SECRETAIRE) }).then(() => {
                this.add_contact = true
                this.liste_postes = this.$store.state.poste.postes
            })

        }
    }

    // Lancer une recherche avec un mot clé NOM pour trouver un contact
    submitRechercherIntervenant (e: Event) {
        e.preventDefault()
        this.$store.commit('centre/SET_ERROR', null)
        if (this.modeResponsable) {
            if (this.name_search !== '') {
                const data = {
                    payload: { name: this.name_search + '%' }
                }
                this.selectedIntervenant = ''
                this.$store
                    .dispatch('affectationResponsable/getUsersFromSearch', data)
                    .then(() => {
                        this.usersListFromSearch = this.$store.state.affectationResponsable.usersFromSearch.data
                        this.showResultats = true
                    })
                    .catch((error) => {
                        console.log('ko:' + error)
                    })
            }
        } else {
            if (this.name_search !== '') {
                const data = {
                    centre_id: this.$store.state.centre.centreSelect.id,
                    payload: { name: this.name_search + '%' }
                }
                this.selectedIntervenant = ''
                this.$store
                    .dispatch('centre/getUsersFromSearch', data)
                    .then(() => {
                        this.usersListFromSearch = this.$store.state.centre.usersFromSearch.data
                        this.showResultats = true
                    })
                    .catch((error) => {
                        console.log('ko:' + error)
                    })
            }
        }
    }

    // Enregistrement du choix du contact existant
    submitChoixIntervenant (e: any) {
        e.preventDefault()
        this.$store.commit('affectationResponsable/SET_ERROR', null)
        this.$store.commit('centre/SET_ERROR', null)
        this.$store.state.centre.error_conflict = null
        this.$store.state.affectationResponsable.error_conflict = null
        this.loading_resp = true

        const postes: Array<any> = []
        for (const p in this.postes_ids) {
            postes.push({
                poste_id: this.postes_ids[p]
            })
        }

        const idInfo = 't_info_' + Math.random()
        const infosToaster = {
            id: idInfo,
            toaster: 'b-toaster-top-right',
            variant: 'primary',
            noCloseButton: true,
            fade: true,
            noAutoHide: true
        }
        this.$bvToast.toast('Enregistrement en cours ...', infosToaster)
        this.msgSuccess_toast = 'Assignation terminée.'
        let data: any = {
            user_id: this.selectedIntervenant,
            centre_id: this.$store.state.centre.centreSelect.id,
            postes: postes,
            responsabilite_centre: this.responsabilite_centre,
            responsabilite_concours: this.responsabilite_concours,
            confirmation: this.confirmation
        }

        let link = 'centre/addDossierUser'
        if (this.modeResponsable) {
            data = {
                user_id: this.selectedIntervenant,
                centre_id: this.$store.state.centre.centreSelect.id,
                confirmation: this.confirmation
            }
            link = 'affectationResponsable/addDossierUser'
        }
        this.$store.dispatch(link, data)
            .then(() => {
                this.loading_resp = false
                this.resetDatas()
                const idSucces = 't_succes_' + Math.random()
                const succesToaster = {
                    id: idSucces,
                    toaster: 'b-toaster-top-right',
                    variant: 'success',
                    noCloseButton: true,
                    fade: true,
                    autoHideDelay: 5000
                }
                this.$bvToast.toast(this.msgSuccess_toast, succesToaster)
                this.addContact = false
                this.$emit('finish_edit')
            })
            .catch(() => {
                this.loading_resp = false
                if (this.$store.state.affectationResponsable.error_conflict && this.$store.state.affectationResponsable.error_conflict.message ||
                this.$store.state.centre.error_conflict && this.$store.state.centre.error_conflict.message) {
                    this.showErrorConflict = true
                    this.showError = false
                    this.lockInput = true
                } else {
                    this.showErrorConflict = false
                    this.showError = true
                    this.messageErreurSimple = 'Une erreur est survenue :'
                }
            })
            .finally(() => {
                this.$bvToast.hide(idInfo)
            })
    }

    // Annuler la recherche d'un contact
    cancelAddContact () {
        this.resetDatas()
    }

    // Met à jour la valeur locale du numéro de téléphone saisi retourné par le composant PhoneInput
    updatePhoneNumberInputData () {
        if ((this.$refs.phoneInput as any)) {
            if ((this.$refs.phoneInput as any).infosPhoneInput) {
                if ((this.$refs.phoneInput as any).infosPhoneInput.isValidatedPhoneNumber) {
                    this.telephone = (this.$refs.phoneInput as any).infosPhoneInput.formattedPhoneNumber
                    this.phoneNumber = (this.$refs.phoneInput as any).infosPhoneInput.formattedPhoneNumber
                } else {
                    this.telephone = ''
                }
            }
        }

        if ((this.$refs.phoneFixeInput as any)) {
            if ((this.$refs.phoneFixeInput as any).infosPhoneInput) {
                if ((this.$refs.phoneFixeInput as any).infosPhoneInput.isValidatedPhoneNumber) {
                    this.telephone_fixe = (this.$refs.phoneFixeInput as any).infosPhoneInput.formattedPhoneNumber
                } else {
                    this.telephone_fixe = ''
                }
            }
        }
    }

    // Met à jour les champs temporaires pour enregistrement
    updateChamps (e: any) {
        switch (e.target.name) {
            case 'civility':
                this.civility = e.target.value
                break
            case 'name':
                this.name_intervenant = e.target.value
                break
            case 'first_name':
                this.first_name = e.target.value
                break
            case 'email':
                this.email = e.target.value
                break
            case 'nameSearch':
                this.name_search = e.target.value
                break
            case 'confirmation':
                this.confirmation = this.confirmation === e.target.value ? 0 : e.target.value
                break
            default:
                break
        }
    }

    // Enregistrement du nouveau contact
    submitDossier (e: any) {
        e.preventDefault()
        this.$store.commit('affectationResponsable/SET_ERROR', null)
        this.$store.commit('centre/SET_ERROR', null)
        this.$store.state.centre.error_conflict = null
        this.$store.state.affectationResponsable.error_conflict = null

        const idInfo = 't_info_' + Math.random()
        const infosToaster = {
            id: idInfo,
            toaster: 'b-toaster-top-right',
            variant: 'primary',
            noCloseButton: true,
            fade: true,
            noAutoHide: true
        }
        this.$bvToast.toast('Enregistrement en cours ...', infosToaster)
        this.msgSuccess_toast = 'Enregistrement terminé.'

        this.updatePhoneNumberInputData()
        const postes: Array<any> = []
        for (const p in this.postes_ids) {
            postes.push({
                poste_id: this.postes_ids[p]
            })
        }

        let data: any = {
            civility: this.civility,
            email: this.email,
            name: this.name_intervenant,
            first_name: this.first_name,
            telephone: this.telephone,
            telephone_fixe: this.telephone_fixe,
            centre_id: this.$store.state.centre.centreSelect.id,
            confirmation: this.confirmation,
            responsabilite_centre: this.responsabilite_centre,
            responsabilite_concours: this.responsabilite_concours,
            postes: postes
        }

        if (this.modeResponsable) {
            data = {
                civility: this.civility,
                email: this.email,
                name: this.name_intervenant,
                first_name: this.first_name,
                telephone: this.telephone,
                telephone_fixe: this.telephone_fixe,
                centre_id: this.$store.state.centre.centreSelect.id,
                confirmation: this.confirmation,
                context: 0
            }
            this.$store.dispatch('affectationResponsable/addDossierUser', data)
                .then(() => {
                    this.resetDatas()
                    // Fermeture de la popup aprés validation
                    const idSucces = 't_succes_' + Math.random()
                    const succesToaster = {
                        id: idSucces,
                        toaster: 'b-toaster-top-right',
                        variant: 'success',
                        noCloseButton: true,
                        fade: true,
                        autoHideDelay: 5000
                    }
                    this.$bvToast.toast(this.msgSuccess_toast, succesToaster)
                    this.$emit('finish_edit')
                    this.createContact = false
                    this.initContact()
                })
                .catch(() => {
                    if (this.$store.state.affectationResponsable.error_conflict && this.$store.state.affectationResponsable.error_conflict.message) {
                        this.showErrorConflict = true
                        this.showError = false
                        this.lockInput = true
                    } else {
                        this.showErrorConflict = false
                        this.showError = true
                        this.messageErreurSimple = 'Une erreur est survenue :'
                    }
                })
                .finally(() => {
                    this.$bvToast.hide(idInfo)
                })
        } else {
            this.$store.dispatch('centre/addDossierUser', data)
                .then(() => {
                    this.resetDatas()
                    // Fermeture de la popup aprés validation
                    const idSucces = 't_succes_' + Math.random()
                    const succesToaster = {
                        id: idSucces,
                        toaster: 'b-toaster-top-right',
                        variant: 'success',
                        noCloseButton: true,
                        fade: true,
                        autoHideDelay: 5000
                    }
                    this.$bvToast.toast(this.msgSuccess_toast, succesToaster)

                    this.$store.dispatch('centre/getCentre', { centre_id: this.$store.state.centre.centreSelect.id }).then(() => {
                        this.$store.dispatch('centre/getCentreUsers', { centre_id: this.$store.state.centre.centreSelect.id }).then(() => {
                            this.$store.dispatch('centre/getUsersCount', { centre_id: this.$store.state.centre.centreSelect.id })
                            this.$emit('finish_edit')
                            this.createContact = false
                            this.initContact()
                        })
                    })
                })
                .catch(() => {
                    if (this.$store.state.centre.error_conflict && this.$store.state.centre.error_conflict.message) {
                        this.showErrorConflict = true
                        this.showError = false
                        this.lockInput = true
                    } else {
                        this.showErrorConflict = false
                        this.showError = true
                        this.messageErreurSimple = 'Une erreur est survenue :'
                    }
                })
                .finally(() => {
                    this.$bvToast.hide(idInfo)
                })
        }
    }

    // Reini les datas
    resetDatas () {
        this.addContact = false
        this.createContact = false
        this.name_intervenant = ''
        this.first_name = ''
        this.email = ''
        this.responsabilite_centre = ''
        this.responsabilite_concours = ''
        this.name_search = ''
        this.telephone = ''
        this.telephone_fixe = ''
        this.showError = false
        this.showErrorConflict = false
        this.messageErreurSimple = ''
        this.lockInput = false
        this.confirmation = 0
        this.showResultats = false
        this.selectedIntervenant = ''
        this.$store.commit('centre/SET_ERROR', null)
        this.$store.state.affectationResponsable.error = null
    }

    beforeMount () {
        this.initContact()
        this.modeResponsable = !!this.mode_responsable;
    }
}
