































































































import { Vue, Component, Watch } from 'vue-property-decorator'
import { Ability } from '@/types/Ability'
import { mapGetters, mapState } from 'vuex'
import ExaGenericTable from '@exatech-group/generic-table/src/GenericTable.vue'
import { formatDate } from '@/utils/helpers'
import PopUpInfoIntervenant from '@/components/PopupInfoIntervenant.vue'
import ContactCentre from '@/components/Centre/ContactCentre.vue'

@Component({
    computed: {
        ...mapGetters('auth', ['authUser', 'can', 'cannot', 'isA', 'isNotA', 'user_session_id']),
        ...mapState('affectationCorrecteur', ['affectationCorrecteurs', 'lastPage', 'totalRows', 'loading']),
        ...mapState('affectationResponsable', ['affectationResponsables']),
        ...mapGetters('auth', ['authUser', 'can', 'cannot', 'isA', 'isNotA', 'user_session_id']),
        ...mapState('auth', ['user', 'authUser', 'user_session_id']),
        ...mapState('session', ['sessionSelect'])
    },
    components: {
        ExaGenericTable,
        PopUpInfoIntervenant,
        ContactCentre
    }
})

export default class AffectationCorrecteurs extends Vue {
    Ability = Ability
    formatDate = formatDate

    params: any = {
        page: 1,
        sort: 'name',
        direction: 'asc'
    }

    filtres:    any         = []
    dataForTab: Array<any>  = []

    valider() {
        console.log('valider')
    }

    exporter() {
        console.log('exporter')
    }

    // ------- modal responsables
    showModalResponsables = false
    modeAjoutResponsable = false
    openModalResponsables() {
        this.modeAjoutResponsable = false
        this.$store.dispatch('affectationResponsable/getAffectationResponsables')
            .then(() => this.showModalResponsables  = true)
    }

    closeModalResponsables () {
        this.showModalResponsables  = false
    }

    validerModalResponsable () {
        this.showModalResponsables  = true
    }

    ajouterResponsable() {
        this.modeAjoutResponsable = true
    }

    dissociateResponsable (user_id: number) {
        const idInfo = 't_info_' + Math.random()
        const infosToaster = {
            id: idInfo,
            toaster: 'b-toaster-top-right',
            variant: 'primary',
            noCloseButton: true,
            fade: true,
            noAutoHide: true
        }
        this.$bvToast.toast('Dissociation en cours ...', infosToaster)
        this.$store.dispatch('affectationResponsable/deleteAffectationResponsable', user_id)
            .then(async () => {
                await this.$store.dispatch('affectationResponsable/getAffectationResponsables')
                const idSucces = 't_succes_' + Math.random()
                const succesToaster = {
                    id: idSucces,
                    toaster: 'b-toaster-top-right',
                    variant: 'success',
                    noCloseButton: true,
                    fade: true,
                    autoHideDelay: 5000
                }
                this.$bvToast.toast('Dissociation terminée.', succesToaster)
            })
            .catch((error) => {
                console.log('ko:' + error)
            })
            .finally(() => {
                this.$bvToast.hide(idInfo)
            })
    }

    quitModeEditResponsable () {
        this.$store.dispatch('affectationResponsable/getAffectationResponsables')
            .then(() => this.modeAjoutResponsable = false)
            .catch((error) => console.log('ko:' + error))
    }

    // ------- modal valider correcteurs
    showModalValiderCorrecteurs = false
    openModalValiderCorrecteurs() {
        this.showModalValiderCorrecteurs  = true
    }

    closeModalValiderCorrecteurs () {
        this.showModalValiderCorrecteurs  = false
    }

    confirmerValiderCorrecteurs () {
        this.$store.dispatch('affectationCorrecteur/synchViatique')
            .then(() => this.showModalValiderCorrecteurs  = false)
    }

    // ----- info Correcteur
    user_id_temp = 0
    showModalInfoIntervenant = false
    infoIntervenantHandler (user_id: number) {
        this.user_id_temp  = user_id
        this.showModalInfoIntervenant = true
    }

    // -------

    // Ensemble des colonnes du tableau
    genericfields = [
        { key: 'filiere.name', label: 'Filière', sortable: false, class: '', type: 'text' },
        { key: 'epreuve.name', label: 'Epreuves', sortable: false, class: '', type: 'text' },
        { key: 'user.name', label: 'Correcteur', sortable: true, class: '', type: 'text' },
        { key: 'transfered_at', label: 'Date de synchronisation', sortable: true, class: '', type: 'text' }
    ]

    // Chargement des données du tableau
    setDataForGenericTab(poData: any, isLoadMore = false) {
        if (!isLoadMore) {
            this.dataForTab = []
        }
        if (poData) {
            for (const result of poData) {
                const filiere: string = [
                    ...new Set(result?.concours
                        ?.map((concour: any) => concour.name) || [])
                ]?.join(', ') || ''

                const epreuves: string = [
                    ...new Set(result?.epreuves
                        ?.map((epreuve: any) => epreuve.name) || [])
                ]?.join(', ') || ''

                const userName = result.user.name + ' ' + result.user.first_name
                let userNameClass = 'text-start text-danger'
                if (result.postes && result.postes.length > 0) {
                    userNameClass = 'text-start text-info item_action'
                }
                const dateSynchro = result.transfered_at ? formatDate(result.transfered_at) : ''
                const line = [
                    { label: '', item: filiere, type: 'text', typeAction: null, class: '' },
                    { label: '', item: epreuves, type: 'text', typeAction: null, class: '' },
                    { label: '', item: result.user.id, type: (userName === '') ? 'text' : 'actionText', typeAction: 'infoIntervenant', class: userNameClass, text: userName },
                    { label: '', item: dateSynchro, type: 'text', typeAction: null, class: '' }
                ]
                this.dataForTab.push(line)
            }
        }
    }

    /**
     * Formatage des datas pour l'affichage dans le tableau générique
     * this.$store.state.ensembleAffectationsExaminateurs.examinateursAffectations
     */
    setFiltersForGenericTab() {
        // Options filières
        const filieres = this.$store.getters['concour/banques']
        const options_filieres = []
        for (const f in filieres) {
            options_filieres.push({ index: filieres[f].id, name: filieres[f].name })
        }

        const matieres = this.$store.getters['matiere/matieres']
        const options_matieres = []
        for (const m in matieres) {
            options_matieres.push({ index: matieres[m].id, name: matieres[m].name })
        }

        this.filtres = [
            { libelle: 'Filière', defautOptionlibelle: 'Rechercher une', model: 'concour.id', value: '', index: 'concour.id',  datas: options_filieres, loading: false, options: { type: 'deroulant', fieldsKey: 'filiere.name' } },
            { libelle: 'Épreuve', defautOptionlibelle: 'Rechercher une', model: 'epreuve.name', value: '', index: 'epreuve.id', datas: '', loading: false, options: { type: 'form', fieldsKey: 'epreuve.name' } },
            { libelle: 'Nom de correcteur', defautOptionlibelle: 'Rechercher un', model: 'user.name', value: '', index: 'user.id', datas: '', loading: false, options: { type: 'form', fieldsKey: 'user.name' } }

            // Filtre sur remplaçant pas fonctionnel pour le moment côté back.
            // { libelle: 'Remplaçant',              defautOptionlibelle: 'Rechercher un',     model: 'remplacant.name',           value: '', index: 'remplacant.name',        datas: '',                  loading: rootStore.loading, options: { type: 'form',      fieldsKey: 'remplacant.name' } }
        ]
    }

    /**
     * Récupération des events du tableau
     * params[0] => l'action
     * params[1] => l'id de l'item
     */
    handleTableEvent (paParams: any): void {
        if (paParams && paParams[0] && paParams[1]) {
            switch (paParams[0]) {
                case 'sortHandler':
                case 'filterHandler':
                    this.filtreSortHandler(paParams[1])
                    break
                case 'onLoadPage':
                    this.loadHandler(paParams[1])
                    break
                case 'infoIntervenant':
                    this.infoIntervenantHandler(paParams[1])
                    break
            }
        }
    }

    // Chargement des données du tableau
    filtreSortHandler (params: any) {
        if (JSON.stringify(this.params) !== JSON.stringify(params)) {
            this.params = params
            // Des filtres sont appliqués on rappele le serveur
            this.$store.dispatch('affectationCorrecteur/getAffectationCorrecteurs', params)
                .then(() => this.setDataForGenericTab(this.$store.state.affectationCorrecteur.affectationCorrecteurs))
        }
    }

    // Appelle une page lors du scroll
    loadHandler (params: any) {
        if (JSON.stringify(this.params) !== JSON.stringify(params)) {
            this.params = params
            this.$store.dispatch('affectationCorrecteur/getMoreAffectationCorrecteurs', params)
                .then(() => this.setDataForGenericTab(this.$store.state.affectationCorrecteur.affectationCorrecteurs))
        }
    }

    @Watch('user_session_id')
    refreshInterface () {
        this.load()
    }

    load() {
        this.$store.dispatch('affectationCorrecteur/getAffectationCorrecteurs').then(() => {
            this.setDataForGenericTab(this.$store.state.affectationCorrecteur.affectationCorrecteurs)
            if (this.$store.getters['concour/banques'].length === 0) {
                this.$store.dispatch('concour/getConcoursActifs').then(() => {
                    this.setFiltersForGenericTab()
                })
            } else {
                this.setFiltersForGenericTab()
            }
        })
    }

    mounted () {
        this.load()
    }
}
